// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ThemeEnum } from '@lon/shared/enums';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { getThemeStyleValue } from '@lon/shared/utils';

export const getTag = (theme: ThemeEnum) => ({
  defaultProps: {
    variant: 'primary',
  },
  baseStyle: {
    label: {
      fontSize: '1rem',
      color: 'primary.800',
    },
  },
  variants: {
    n_solid: {
      container: {
        backgroundColor: 'primary.800',
        color: 'white',
        borderRadius: '4px',
        width: 'fit-content',
        textTransform: 'uppercase',
      },
    },
    n_subtle: {
      container: {
        backgroundColor: 'primary.30',
        color: 'primary.700',
        borderRadius: '4px',
        width: 'fit-content',
        textTransform: 'uppercase',
      },
    },
    n_outline: {
      container: {
        backgroundColor: 'white',
        color: 'primary.700',
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: 'primary.700',
        width: 'fit-content',
        textTransform: 'uppercase',
      },
    },
    primary: {
      container: {
        fontWeight: 600,
        fontSize: 'sm',
        bg: 'gray.25',
        border: '1px solid',
        borderColor: 'secondary.50',
        borderRadius: '10px',
      },
    },
    solid: {
      container: {
        bg: 'primary.800',
        color: 'white',
        borderRadius: '4px',
        width: 'fit-content',
      },
    },
    secondary: {
      container: {
        bg: 'blue.150',
      },
    },
    outline: {
      container: {
        px: 2.5,
        py: 0.5,
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: 'primary.700',
        color: 'primary.700',
        w: 'auto',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        boxShadow: 'none',
      },
      label: {
        fontSize: 'sm',
      },
    },
    info: {
      container: {
        bg: getThemeStyleValue('secondary.100', 'secondary.900')(theme),
        color: getThemeStyleValue('primary.800', 'white')(theme),
        fontSize: 'xs',
        lineHeight: '1rem',
        py: 0.5,
        px: 2,
        minH: '20px',
        w: 'fit-content',
      },
    },
    subtle: {
      container: {
        bg: 'primary.30',
        color: 'primary.700',
        fontSize: '1rem',
        lineHeight: '1.25rem',
        py: 1,
        px: 2.5,
        minH: '20px',
        w: 'fit-content',
      },
    },
    status: {
      container: {
        bg: 'secondary.100',
        color: 'primary.800',
        fontSize: 'xs',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        borderRadius: 'base',
        py: 1,
        px: 2,
        minH: '20px',
        w: 'fit-content',
        whiteSpace: 'nowrap',
      },
    },
    studentStatus: {
      container: {
        bg: 'secondary.100',
        color: 'primary.800',
        fontSize: 'xs',
        lineHeight: '1rem',
        textTransform: 'uppercase',
        borderRadius: '4px',
        py: 0.5,
        px: 2,
        minHeight: '20px',
        w: 'fit-content',
        whiteSpace: 'nowrap',
      },
    },
    group: {
      container: {
        py: 1,
        px: 2,
        borderRadius: 'md',
        borderWidth: '1px',
        borderColor: 'primary.800',
        w: 'auto',
      },
    },
    successOutline: {
      container: {
        bg: 'olive.50',
        color: 'olive.100',
        borderColor: 'olive.100',
        borderRadius: 'md',
        borderWidth: '1px',
      },
    },
    warningOutline: {
      container: {
        bg: 'yellow.extraLight',
        color: '#97470E',
        borderColor: '#97470E',
        borderRadius: 'md',
        borderWidth: '1px',
      },
    },
    dangerOutline: {
      container: {
        bg: 'danger.extraLight',
        color: 'danger.800',
        borderColor: 'danger.800',
        borderRadius: 'md',
        borderWidth: '1px',
      },
    },
    violet: {
      container: {
        bg: 'calendar.violet.extraLight',
        color: 'calendar.violet.2',
        borderColor: 'calendar.violet.2',
        borderRadius: 'md',
        borderWidth: '1px',
      },
    },
    calendarVioletOutline: {
      container: {
        bg: 'calendar.violet.extraLight',
        color: 'calendar.violet.2',
        borderColor: 'calendar.violet.2',
        borderRadius: 'base',
        borderWidth: '1px',
      },
    },
    calendarBlueOutline: {
      container: {
        bg: 'calendar.blue.100',
        color: 'calendar.blue.800',
        borderColor: 'calendar.blue.800',
        borderRadius: 'base',
        borderWidth: '1px',
      },
    },
    yellowOutline: {
      container: {
        bg: 'calendar.yellow.extraLight',
        color: 'calendar.yellow.800',
        borderColor: 'calendar.yellow.800',
        borderRadius: 'xl',
        borderWidth: '1px',
      },
    },
    calendarYellowOutline: {
      container: {
        bg: 'calendar.yellow.extraLight',
        color: 'calendar.yellow.800',
        borderColor: 'calendar.yellow.800',
        borderRadius: 'base',
        borderWidth: '1px',
      },
    },
    calendarOrangeOutline: {
      container: {
        bg: 'calendar.orange.extraLight',
        color: 'calendar.orange.d',
        borderColor: 'calendar.orange.d',
        borderRadius: 'base',
        borderWidth: '1px',
      },
    },
    disabledOutline: {
      container: {
        bg: 'secondary.50',
        color: 'secondary.300',
        borderColor: 'secondary.300',
        borderRadius: 'base',
        borderWidth: '1px',
      },
    },
    successIntegration: {
      container: {
        bg: 'olive.50',
        color: 'olive.100',
        borderColor: 'olive.100',
        borderRadius: '0.75rem',
        fontSize: '0.75rem',
        borderWidth: '1px',
        whiteSpace: 'pre',
      },
    },
    dangerIntegration: {
      container: {
        bg: 'danger.extraLight',
        color: 'danger.800',
        borderColor: 'danger.800',
        borderRadius: '0.75rem',
        fontSize: '0.75rem',
        borderWidth: '1px',
        whiteSpace: 'pre',
      },
    },
    startedIntegration: {
      container: {
        bg: 'warning.extraLight',
        color: 'warning.text',
        borderColor: 'warning.text',
        borderRadius: '0.75rem',
        fontSize: '0.75rem',
        borderWidth: '1px',
      },
    },
    showMoreOutline: {
      container: {
        borderWidth: '1px',
        borderRadius: '12px',
      },
    },
    counter: {
      container: {
        borderRadius: '12px',
        borderColor: 'primary.700',
        background: 'primary.30',
        borderWidth: '1px',
      },
    },
    classTeacher: {
      container: {
        borderRadius: '12px',
        background: 'secondary.50',
        color: 'primary.700',
      },
    },
    infoOutline: {
      container: {
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: 'primary.700',
        color: 'primary.700',
      },
    },
    infoOutlineWithBgr: {
      container: {
        borderRadius: '4px',
        borderWidth: '1px',
        borderColor: 'primary.700',
        background: 'primary.30',
        color: 'primary.700',
      },
    },
  },
  sizes: {
    txs: {
      container: {
        fontSize: '0.6875rem',
        fontWeight: 400,
        lineHeight: '0.6875rem',
        py: '0.1875rem',
        px: 2,
      },
    },
    xss: {
      container: {
        height: '3.5',
        minHeight: 'unset',
        minWidth: 'unset',
        paddingX: '0.3125rem',
        paddingY: '0.5',
      },
    },
    xxs: {
      container: {
        minHeight: '14px',
        fontSize: '0.5625rem',
        lineHeight: '0.5625rem',
        paddingY: '0.5',
        paddingInlineStart: 1.5,
        paddingInlineEnd: 1.5,
      },
    },
    xs: {
      container: {
        minHeight: '16px',
        fontSize: '0.625rem',
        lineHeight: '0.625rem',
        paddingY: '0.5',
        paddingInlineStart: 1.5,
        paddingInlineEnd: 1.5,
      },
    },
    sm: {
      container: {
        minHeight: '20px',
        fontSize: '0.6875rem',
        lineHeight: '0.6875rem',
        paddingY: '0.5',
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
      },
    },
    md: {
      container: {
        minHeight: '22px',
        fontSize: '0.75rem',
        lineHeight: '0.75rem',
        paddingY: '0.5',
        paddingInlineStart: 2,
        paddingInlineEnd: 2,
      },
    },
    lg: {
      container: {
        minHeight: '24px',
        fontSize: '0.875rem',
        lineHeight: '0.875rem',
        paddingInlineStart: 2.5,
        paddingInlineEnd: 2.5,
      },
    },
  },
});
