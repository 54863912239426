// TODO check on correct environment when prod
import createCache from '@emotion/cache';
import {
  ApplicationEnum,
  ProfileTypeEnum,
  StudentAssignmentStatusTransformed,
} from '@lon/shared/enums';
import {
  GetDistrictSettingsQuery,
  GradeLevelEnum,
  SetupStatusEnum,
} from '@lon/shared/requests';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { OperationEnum } from '@lon/shared/types';

export const SITE_HEADER_HEIGHT = 76;
export const WIZARD_HEIGHT = 64;
export const CHILD_SITE_HEADER_HEIGHT = 90;
export const PAGE_HEADER_HEIGHT = 56;
export const TOOLBAR_HEIGHT = 48;
export const TOOLBAR_MENU_CONTENT_PADDING = 96;
export const CONTENT_ACTIONS_TOOLBAR_HEIGHT = 52;
export const DESKTOP_CONTENT_PADDING = 16;
export const DESKTOP_CHILD_CONTENT_PADDING = 20;
export const SITE_FOOTER_HEIGHT = 56;
export const SITE_HEADER_Z_INDEX_BASE = 10;
export const SITE_HEADER_Z_INDEX_MD = 11;
export const SITE_FOOTER_Z_INDEX_BASE = 9;
export const SITE_FOOTER_Z_INDEX_MD = 10;
export const SIDEBAR_Z_INDEX = 10;
export const IMPERSONATE_BORDER_WIDTH_REM = 0.5;
export const IMPERSONATE_BORDER_BOTTOM_WIDTH_REM = 3.25;
export const IMPERSONATE_WRAPPER_HEIGHT_REM =
  IMPERSONATE_BORDER_WIDTH_REM + IMPERSONATE_BORDER_BOTTOM_WIDTH_REM;
export const IMPERSONATE_WRAPPER_WIDTH_REM = IMPERSONATE_BORDER_WIDTH_REM * 2;
export const MAX_NUMBER_OF_SCOPES_PER_QUERY = 1000;

export const isDevMode = () => process.env.NODE_ENV === 'development';

export const isReviewSite = () =>
  window.location.search.includes('?isReview') ||
  window.location.host.includes('review');

export const checkIsMathnation = () =>
  window.location.pathname.startsWith('/mathnation');

export const isDevEnv = () =>
  window.location.host.includes(
    'stem.dev.stemscopes-v4-dev.aws.acceleratelearning.com'
  ) && !window.location.host.startsWith('local.');
export const isReleaseEnv = () =>
  window.location.host.includes(
    'stem.release.stemscopes-v4-dev.aws.acceleratelearning.com'
  ) && !window.location.host.startsWith('local.');
export const isMainEnv = () =>
  window.location.host.includes(
    'stem.main.stemscopes-v4-dev.aws.acceleratelearning.com'
  ) && !window.location.host.startsWith('local.');
export const isMainReviewEnv = () =>
  window.location.host.includes('main-review');
export const isTexasReviewEnv = () =>
  window.location.host.includes('texas.review') ||
  window.location.search === '?isReview=tx';
export const isFloridaReviewEnv = () =>
  window.location.host.includes('florida.review') ||
  window.location.host.includes('rvw-fl-uat') ||
  window.location.search === '?isReview=fl';

export const isUatEnv = () => window.location.host.includes('uat');
export const isProdEnv = () =>
  window.location.host.includes('stem.acceleratelearning.com');
export const isProdCluster = () =>
  isUatEnv() || isProdEnv() || isTexasReviewEnv();

export const determineEnv = () => {
  if (isDevEnv()) {
    return 'dev';
  }
  if (isReleaseEnv()) {
    return 'release';
  }
  if (isMainEnv()) {
    return 'main';
  }
  if (isUatEnv()) {
    return 'uat';
  }
  if (isMainReviewEnv()) {
    return 'main-review';
  }
  if (isTexasReviewEnv()) {
    return 'texas-review';
  }
  if (isFloridaReviewEnv()) {
    return 'florida-review';
  }
  return 'unknown';
};
export const STEMSCOPES_ADOPTION_EVENT_LOGIN_URL = `https://${window.location.hostname.replace(
  'local.',
  ''
)}/login/review`;
export const MATHNATION_ADOPTION_EVENT_LOGIN_URL = `https://${window.location.hostname.replace(
  'local.',
  ''
)}/mathnation/login/review`;

export const ADOPTION_ACCOUNT_LOGIN_URL = `https://${window.location.hostname.replace(
  'local.',
  ''
)}/login/adoption`;

export const MATHNATION_ADOPTION_ACCOUNT_LOGIN_URL = `https://${window.location.hostname.replace(
  'local.',
  ''
)}/mathnation/login/adoption`;

export const ERROR_CODES = {
  validation_constraint_not_blank: '002',
  validation_constraint_not_unique_username: '006',
  validation_constraint_too_short: '007',
  validation_incorrect_login_or_password: '010',
  internal_server_error: '038',
  validation_constraint_not_unique_value: '013',
  too_low_error: '024',
  validation_constraint_not_unique_email: '041',
  validation_constraint_not_unique: '013',
  validation_constraint_email_not_valid: '005',
  validation_constraint_user_is_inactive: '077',
  validation_too_large_file: '044',
  access_denied: '999',
  too_few_error: '064',
  validation_constraint_token_expired_or_invalid: '090',
  validation_constraint_user_not_found: '078',
  validation_constraint_password_basic: '080',
  validation_constraint_password_admin_suit: '080',
  validation_constraint_password_previous: '081',
  password_mismatch: '013',
  password_incorrect: '082',
  PASSWORD_INCORRECT: '082',
  VALIDATION_CONSTRAINT_EMAIL_UNIQUE: '005',
  VALIDATION_CONSTRAINT_PASSWORD_BASIC: '080',
  VALIDATION_CONSTRAINT_CLASS_NAME_UNIQUE: '100',
  VALIDATION_CONSTRAINT_PASSWORD_PREVIOUS: '106',
  VALIDATION_CONSTRAINT_PASSWORD_ADMIN_SUIT: '082',
  VALIDATION_CONTAINS_PASSWORD_FIRST_NAME_OR_LAST_NAME: '107',
  validation_contains_password_first_name_or_last_name: '108',
  USERNAME_DISTRICT_UNIQUE_VALIDATION: '109',
};

export const RESERVED_MESSAGES: Record<string, string> = {
  "Unexpected '<'": 'Sorry, the application encountered an issue.',
  "Unexpected '&'": 'Sorry, the application encountered an issue.',
  "Unexpected '|'": 'Sorry, the application encountered an issue.',
};

export enum NETWORK_ERROR_CODES {
  unauthorized = 'UNAUTHORIZED',
  sessionExpired = 'SESSION_EXPIRED',
  internalServerError = 'Internal server error',
  passwordExpired = 'PASSWORD_EXPIRED',
  inactiveUser = 'USER_INACTIVE',
  sessionInvalidated = 'SESSION_INVALIDATED',
  mfaRequired = 'MFA_CODE_REQUIRED',
}

export const EMAIL_VALIDATION_REGEXP = new RegExp(
  '^(([^<>()[\\]\\\\.,;:\\s@"]+(\\.[^<>()[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))'
);

export const MODALS = {
  SessionExpired: 'SESSION_EXPIRED',
  ContinueUsing: 'CONTINUE_USING',
  PasswordUpdate: 'PASSWORD_UPDATE',
  MfaCode: 'MFA_CODE_REQUIRED',
};

export const DEFAULT_PAGINATION_SIZE = 30;

export const PASSWORD_CHARS = [
  '0123456789',
  'ABCDEFGHIJKLMNPQRSTUVWXYZ',
  'abcdefghijklmnpqrstuvwxyz',
  '!"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~',
];

export const LANGUAGES = [
  { label: 'English', value: 'en' },
  { label: 'Spanish', value: 'es' },
];

export const STATUSES = [
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

export const dateFormats = {
  monthDayYear: 'MM/dd/yyyy',
};

export const skipToastOperations = [
  'addStudentsToClass',
  'getCleverSchools',
  'editIntegration',
  'getLatestPreviewAccountImportLog',
  'getPreviewAccount',
  'getAdoptionAccount',
  'getProduct',
  'getProductGroup',
  'putAssignment',
  'createStemscopesAssessment',
  'copyAssessment',
  'getEdfiRoles',
  'getEdfiSchools',
  'getEdfiGrades',
  'getAllAssessments',
  'deleteTutor',
];

export const sxLightRedScrollBar = {
  '&::-webkit-scrollbar': {
    backgroundColor: 'var(--chakra-colors-danger-400)',
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-danger-600)',
  },
};

export const sxLightScrollBar = {
  '&::-webkit-scrollbar': {
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#DBDFE6',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-primary-500)',
  },
};

export const sxHighContrastScrollBar = {
  ...sxLightScrollBar,

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#A0AEC266',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-teal-500)',
  },
};

export const sxLightScrollBarHiddenX = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#DBDFE6',
    width: '10px',
    height: 0,
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-primary-500)',
  },
};

export const sxRoundedLightScrollBarHiddenX = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: 0,
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#225B90',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#ABCAE7',
    borderRadius: '8px',
  },
};

export const sxDarkScrollBar = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#DBDFE6',
    width: '6px',
    height: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'var(--chakra-colors-primary-500)',
  },
};

export const sxSidebarDarkScrollBar = {
  '&::-webkit-scrollbar': {
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    width: '10px',
    height: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'primary.300',
  },
};

export const sxRoundedDarkScrollBar = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: '10px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: 'primary.300',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    margin: '40px auto',
    backgroundColor: 'rgba(255, 255, 255, 0.15)',
    borderRadius: '8px',
  },
};

export const sxRoundedContentScrollbar = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: '16px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#225B90',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    margin: 'var(--chakra-space-8) var(--chakra-space-6)',
    backgroundColor: '#ABCAE7',
    borderRadius: '8px',
  },

  '@media all and (max-width: 1023px)': {
    '&::-webkit-scrollbar-track': {
      margin: 'var(--chakra-space-6)',
    },
  },

  '@media all and (max-width: 767px)': {
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
    },

    '&::-webkit-scrollbar-track': {
      margin: 'var(--chakra-space-4)',
      borderRadius: '5px',
    },
  },
};

export const sxRoundedHighContrastScrollbar = {
  ...sxRoundedContentScrollbar,

  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'var(--chakra-colors-teal-500)',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    margin: 'var(--chakra-space-8) var(--chakra-space-6)',
    backgroundColor: '#A0AEC266',
    borderRadius: '8px',
  },
};

export const roundedBigScrollbar = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: '16px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#225B90',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    margin: 'var(--chakra-space-8) var(--chakra-space-6)',
    backgroundColor: '#ABCAE7',
    borderRadius: '8px',
  },
};

export const sxRoundedDarkContentScrollbar = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: '16px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#8FB7DC',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    margin: 'var(--chakra-space-8) var(--chakra-space-6)',
    backgroundColor: '#225B90',
    borderRadius: '8px',
  },

  '@media all and (max-width: 1023px)': {
    '&::-webkit-scrollbar-track': {
      margin: 'var(--chakra-space-6)',
    },
  },

  '@media all and (max-width: 767px)': {
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
    },

    '&::-webkit-scrollbar-track': {
      margin: 'var(--chakra-space-4)',
      borderRadius: '5px',
    },
  },
};

export const sxRoundedDarkThumbScrollBar = {
  '&::-webkit-scrollbar': {
    width: '16px',
    height: '16px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#225B90',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-track': {
    backgroundColor: '#ABCAE7',
    borderRadius: '8px',
  },

  '@media all and (max-width: 767px)': {
    '&::-webkit-scrollbar': {
      width: '10px',
      height: '10px',
    },

    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
    },

    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
    },
  },
};

export const sxRoundedLightThumbScrollBar = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#225B90',
    width: '16px',
    height: '10px',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#8FB7DC',
    borderRadius: '8px',
  },
};

export const sxRoundedExtraLightScrollBar = {
  '&::-webkit-scrollbar': {
    backgroundColor: '#1E41760D',
    width: '16px',
    height: '10px',
    borderRadius: '8px',
  },

  '&::-webkit-scrollbar-thumb': {
    background: '#8FB7DC',
    borderRadius: '8px',
  },
};

export const showOutletOnly = window.location.pathname.includes(
  'preview-student-platform'
);

export const availableSuits = [
  { name: 'admin' },
  { name: 'teacher', label: 'Test School 1', id: '1' },
  { name: 'teacher', label: 'Test School 2', id: '2' },
  { name: 'student', label: 'Test School 3', id: '3' },
  { name: 'student', label: 'Test School 4', id: '4' },
  { name: 'student', label: 'Test School 5', id: '5' },
];

export const EARLY_DATE = '1970-01-01';

export const STATUS_SEQUENCE = {
  [StudentAssignmentStatusTransformed.Assigned]: 1,
  [StudentAssignmentStatusTransformed.Started]: 2,
  [StudentAssignmentStatusTransformed.ToBeGraded]: 3,
  [StudentAssignmentStatusTransformed.Graded]: 4,
  [StudentAssignmentStatusTransformed.Returned]: 5,
};

export const belowK3GradeLevels = [
  GradeLevelEnum.GlPreK,
  GradeLevelEnum.GlK,
  GradeLevelEnum.Gl_1,
  GradeLevelEnum.Gl_2,
];

export const isMobileDevice =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
    navigator.userAgent
  ) ||
  (/Mac/.test(navigator.userAgent) && !!navigator.maxTouchPoints);

// list of OWASP special characters
// eslint-disable-next-line no-useless-escape
export const specialCharacters = /[!"#$%&'()*+,-.:;<=>?@[\\\]^_`{|}~\/]/;

export const accessDenied = 'access denied';

export const ORDERED_KEYS = [
  'key',
  'X-Amz-Security-Token',
  'X-Amz-Credential',
  'X-Amz-Algorithm',
  'X-Amz-Date',
  'Policy',
  'X-Amz-Signature',
];

export const CSV_ORDERED_KEYS = [
  'key',
  'X-Amz-Security-Token',
  'X-Amz-Credential',
  'X-Amz-Algorithm',
  'X-Amz-Date',
  'Policy',
  'X-Amz-Signature',
  'x-amz-meta-original-filename',
];

export const MIN_PROGRESS_THRESHOLD = 7;

export const gradesOrder = [
  'pk',
  'k',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '11',
  '12',
];

export enum FontSizeEnum {
  small = 'small',
  default = 'default',
  medium = 'medium',
  large = 'large',
}

export enum FontFamilyEnum {
  report = 'report',
  timesNewRoman = 'timesNewRoman',
  verdana = 'verdana',
}

export const fontSizesMapping = {
  [FontFamilyEnum.verdana]: {
    [FontSizeEnum.small]: {
      fontSize: '14px',
      lineHeight: '23px',
    },
    [FontSizeEnum.default]: {
      fontSize: '16px',
      lineHeight: '26px',
    },
    [FontSizeEnum.medium]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    [FontSizeEnum.large]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
  },
  [FontFamilyEnum.timesNewRoman]: {
    [FontSizeEnum.small]: {
      fontSize: '16px',
      lineHeight: '23px',
    },
    [FontSizeEnum.default]: {
      fontSize: '18px',
      lineHeight: '27px',
    },
    [FontSizeEnum.medium]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [FontSizeEnum.large]: {
      fontSize: '22px',
      lineHeight: '33px',
    },
  },
  [FontFamilyEnum.report]: {
    [FontSizeEnum.small]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [FontSizeEnum.default]: {
      fontSize: '22px',
      lineHeight: '33px',
    },
    [FontSizeEnum.medium]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [FontSizeEnum.large]: {
      fontSize: '26px',
      lineHeight: '39px',
    },
  },
};

export const fontSizesMappingBelowK3 = {
  [FontFamilyEnum.timesNewRoman]: {
    [FontSizeEnum.small]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [FontSizeEnum.default]: {
      fontSize: '22px',
      lineHeight: '33px',
    },
    [FontSizeEnum.medium]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [FontSizeEnum.large]: {
      fontSize: '26px',
      lineHeight: '39px',
    },
  },
  [FontFamilyEnum.report]: {
    [FontSizeEnum.small]: {
      fontSize: '20px',
      lineHeight: '30px',
    },
    [FontSizeEnum.default]: {
      fontSize: '22px',
      lineHeight: '33px',
    },
    [FontSizeEnum.medium]: {
      fontSize: '24px',
      lineHeight: '36px',
    },
    [FontSizeEnum.large]: {
      fontSize: '26px',
      lineHeight: '39px',
    },
  },
};

export const fontFamilyMapping = {
  [FontFamilyEnum.verdana]:
    'Verdana, Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
  [FontFamilyEnum.timesNewRoman]:
    'Times New Roman, Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
  [FontFamilyEnum.report]:
    'Report, Lato, Helvetica Neue, Arial, Helvetica, sans-serif',
};

export const useDisclosureMock = {
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
  onToggle: () => {},
  isControlled: false,
  getButtonProps: () => {},
  getDisclosureProps: () => {},
};

export const ORDERED_NAMES = [
  '6',
  '6th Grade',
  '7',
  '7th Grade',
  '8',
  '8th Grade',
  'Algebra 1',
  'Geometry',
  'Algebra 2',
  'IM 6',
  'IM 6th Grade',
  'IM 7',
  'IM 7th Grade',
  'IM 8',
  'IM 8th Grade',
  'IM Algebra 1',
  'IM Geometry',
  'IM Algebra 2',
  'FL 6',
  'FL 6th Grade',
  'FL 6th Grade Accelerated',
  'FL 7',
  'FL 7th Grade',
  'FL 7th Grade Accelerated',
  'FL 8',
  'FL 8th Grade',
  'FL Algebra 1',
  'FL Geometry',
  'FL Algebra 2',
  'ACT',
  'ACT Prep',
  'SAT',
  'SAT Prep',
  'PSAT 8',
  'PSAT 8th Grade',
  'PSAT 8 Prep',
  'PSAT 8/9 Prep',
  'PSAT 9',
  'PSAT 9th Grade',
  'PSAT 9 Prep',
  'PSAT 10',
  'PSAT 10th Grade',
  'PSAT 10 Prep',
  'PSAT 10th Grade Prep',
  'PSAT 10/NMSQT Prep',
];

export const defaultVoices = {
  spanish: 'Francisco',
  english: 'Sophie',
};

export const ERROR_MESSAGES: Record<string, string> = {
  BLANK_FIELD: "can't be blank",
  FIND_URL_BLANK_FIELD: 'This field could not be empty',
  INVALID_CREDENTIALS: 'The Credentials provided are not valid.',
  PREVIEW_ACCOUNT_PERIOD_HAS_NOT_STARTED:
    'Preview Account period hasn’t started',
  PREVIEW_ACCOUNT_HAS_EXPIRED: 'Preview Account has expired',
  GENERAL_ERROR: 'Some error has occurred. Please try again later.',
  LOGIN_LOCKED:
    'Your account is temporarily blocked for 15 minutes for security reasons. Please try again in 15 minutes.',
  INACTIVE_USER:
    'Your account is inactive. Please contact your administrator for the detailed information.',
  PREVIEW_ACCOUNT_HAS_NO_PRODUCTS:
    'No related Products with the Preview Account. Product deleted in the external system.',
  PREVIEW_ACCOUNT_SELF_REGISTRATION_REQUIRED:
    'Preview Account registration required',
  MFA_CODE_REQUIRED: 'Multi Factor Authentication required',
  INVALID_MFA_CODE: 'Verification Code is invalid',
  MFA_CODE_EXPIRED: 'Verification Code is expired',
  LOGIN_UNAVAILABLE:
    'Your account is inactive. Please contact your administrator for the detailed information.',
  ADOPTION_ACCOUNT_DEACTIVATED_MANUALLY:
    'The credentials provided are not valid.',
};

export const calendarIdPrefix = 'api/calendars/';
export const entriesIdPrefix = 'api/entries/';

export * as KEYBOARD from './keyboard';

export const stemDomain =
  process.env['NX_STEMSCOPES_URL'] || 'https://dev2019.stemscopes.com';

export const prodStemDomain = 'https://www.acceleratelearning.com';

export const emotionCache = createCache({
  key: 'emotion-css-cache',
  prepend: true,
});

export const CODING24 = 'CODING24';

export const profileTypeByApplication: Record<
  Exclude<ApplicationEnum, ApplicationEnum.LOGIN>,
  ProfileTypeEnum
> = {
  [ApplicationEnum.CAMPUS_LEADER_SUIT]: ProfileTypeEnum.CAMPUS_LEADER,
  [ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT]:
    ProfileTypeEnum.CAMPUS_CURRICULUM_LEADER,
  [ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT]:
    ProfileTypeEnum.DISTRICT_CURRICULUM_LEADER,
  [ApplicationEnum.STUDENT_SUIT]: ProfileTypeEnum.STUDENT,
  [ApplicationEnum.TEACHER_SUIT]: ProfileTypeEnum.TEACHER,
  [ApplicationEnum.PARENT_SUIT]: ProfileTypeEnum.PARENT,
  [ApplicationEnum.ADMIN]: ProfileTypeEnum.ADMIN,
  [ApplicationEnum.PLATFORM_ADMIN]: ProfileTypeEnum.PLATFORM_ADMIN,
  [ApplicationEnum.MATHNATION_SUIT]: ProfileTypeEnum.TEACHER,
};

export const applicationSortOrder = [
  ApplicationEnum.PLATFORM_ADMIN,
  ApplicationEnum.ADMIN,
  ApplicationEnum.CAMPUS_LEADER_SUIT,
  ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT,
  ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT,
  ApplicationEnum.TEACHER_SUIT,
  ApplicationEnum.STUDENT_SUIT,
  ApplicationEnum.MATHNATION_SUIT,
];

export const OTHER_ASSIGNMENTS_ID = 'other-assignments';

export const isMac = /Mac/.test(navigator.userAgent);

export const defaultDistrictSettings: GetDistrictSettingsQuery = {
  activeContentGroups: [],
  districtSettingsKnowledgeBase: {
    studentCanUsePerryBot: false,
    teacherCanUsePerryBot: false,
  },
  districtSettingsLessonPlanner: {
    teacherCanUseAiAssistedLessonPlan: false,
  },
  districtSettingsRoster: {
    teacherCanAddAdditionalTeacher: false,
    teacherCanCreateClasses: false,
    teacherCanEditClassRoster: false,
  },
  districtSettingsUserManagement: {
    teacherCanCreateStudents: false,
    teacherCanEditStudentPassword: false,
    teacherCanImpersonateStudents: false,
  },
  districtSetupStatus: {
    status: SetupStatusEnum.Completed,
  },
};

export const teacherView = 'teacherView';
export const studentView = 'studentView';
export const showAnswers = 'ON';
export const hideAnswers = 'OFF';

export const CHILD_PAGE_HEADER_HEIGHT = 65;
export const studentNoteMaxLength = 255;

export const showTTS = 'ON';
export const hideTTS = 'OFF';
export const planIdPrefix = 'api/scope_plans/';
export const FULL_HEIGHT = isMobileDevice ? '100dvh' : '100vh';

export const DATE_FORMAT = 'dd/MM/yyyy';
export const DATE_TIME_FORMAT = 'dd/MM/yyyy, HH:mm';
export const IMPORTANT_LOCAL_STORAGE_KEYS = [
  'lastApplicationBySignature',
  'lastSchoolIdsBySignature',
  'lastDistrictBySignature',
  'state',
  'signature',
  'previewLoginState',
];

export const rosterSettings = [
  {
    key: 'districtSettingsRoster.teacherCanCreateClasses',
    name: OperationEnum.create,
  },
  {
    key: 'districtSettingsRoster.teacherCanEditClassRoster',
    name: OperationEnum.addStudents,
  },
  {
    key: 'districtSettingsRoster.teacherCanAddAdditionalTeacher',
    name: OperationEnum.addTeachers,
  },
];
export const umSettings = [
  {
    key: 'districtSettingsUserManagement.teacherCanEditStudentPassword',
    name: OperationEnum.editPassword,
  },
  {
    key: 'districtSettingsUserManagement.teacherCanImpersonateStudents',
    name: OperationEnum.impersonate,
  },
  {
    key: 'districtSettingsUserManagement.teacherCanCreateStudents',
    name: OperationEnum.create,
  },
];
export const lessonPlanner = [
  {
    key: 'districtSettingsLessonPlanner.teacherCanUseAiAssistedLessonPlan',
    name: OperationEnum.access,
  },
];
export const learningBot = [
  {
    key: 'districtSettingsKnowledgeBase.teacherCanUsePerryBot',
    name: OperationEnum.access,
  },
];

const getKeys = (arrays: any) =>
  arrays?.map((arr: any) => arr.map((item: any) => item.key));
export const districtSettingsKeys = getKeys([
  rosterSettings,
  umSettings,
  lessonPlanner,
  learningBot,
]);
export const districtSettings = {
  rosterSettings,
  umSettings,
  lessonPlanner,
  learningBot,
};
