import { UseToastOptions } from '@chakra-ui/react';
import React from 'react';
import { FunctionComponent, SVGProps } from 'react';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  ERROR_CODES,
  FontFamilyEnum,
  FontSizeEnum,
} from '@lon/shared/constants';
import {
  ApplicationEnum,
  DistrictPermission,
  ProfileTypeEnum,
  StudentAssignmentStatusTransformed,
  ThemeEnum,
} from '@lon/shared/enums';
import {
  AclApplication,
  CalendarTypeEnum,
  EntryTypeEnum,
  GetLookUpRolesQuery,
  GradeLevelEnum,
  PacingGuideTypeEnum,
  PageInfo,
  ScopePlanApproachEnum,
  Scope as ScopeQuery,
  ScopesPacingJoinedQuery,
  SearchableScope as SearchableScopeQuery,
  ShareResults,
  StudentAssignmentStatus as StudentAssignmentStatusEnum,
  TeacherResource as TeacherResourceQuery,
  UserPurposeEnum,
  UserTypeEnum,
  ViewModeEnum,
} from '@lon/shared/requests';

export type SpeedName = 'slow' | 'regular' | 'fast';

export interface AclPermission extends AclApplication {
  permissionName?: DistrictPermission;
}

export interface BaseProps {
  children?: React.ReactNode;
  isCentered?: boolean;
  isSelectable?: boolean;
}

export type CalendarsType = Array<{
  __typename?: 'Calendar';
  _id: string;
  pk: string;
  name?: string | null;
  isDefault?: boolean | null;
  calendarId?: string | null;
  calendarTemplateId?: string | null;
  version?: number | null;
  editable?: boolean | null;
  calendarType?: CalendarTypeEnum | null;
  userId?: string | null;
  creator?: string | null;
  calendarEntries?: {
    __typename?: 'EntryPageConnection';
    collection?: Array<{
      __typename?: 'Entry';
      id: string;
      _id: string;
      eventId?: string | null;
      eventTemplateId?: string | null;
      name: string;
      type: EntryTypeEnum;
      startDate: string;
      endDate?: string | null;
      notes?: string | null;
      editable?: boolean | null;
      version?: number | null;
    } | null> | null;
  } | null;
  color?: string;
  foregroundColor?: string;
} | null> | null;

export type CalendarListType = Array<{
  __typename?: 'userCalendars';
  id: string;
  _id: string;
  name: string;
  calendarId: string;
  calendarType: string;
  isDefault: boolean;
  isSelected?: boolean | null;
  editable: boolean;
  userId: string;
  version?: number | null;
  creator?: string;
  userSelection: {
    __typename?: 'UserSelection';
    id: string;
    pk: string;
    sk: string;
    calendarSettingsPopup?: boolean | null;
    selectedCalendar?: any | null;
    viewMode?: ViewModeEnum | null;
  };
  color?: string;
  foregroundColor?: string;
} | null> | null;

export interface LearnosityAnswer {
  response_id: string;
  question_type: string;
  dt_score_updated: Date;
  automarkable: boolean;
  attempted: boolean;
  score: number;
  max_score: number;
  question_reference: string;
  item_reference: string;
  response: {
    value: [string];
    type: string;
    apiVersion: string;
    revision: number;
  };
}
export interface ToastProps extends UseToastOptions {
  firstLink?: string;
  secondLink?: string;
  firstLinkText?: string;
  secondLinkText?: string;
  variant:
    | 'success'
    | 'error'
    | 'info'
    | 'error-dark'
    | 'error-light'
    | 'success-dark'
    | 'success-light'
    | 'warning-dark'
    | 'warning-light'
    | 'info-dark'
    | 'info-light';
}

export type ToastFn = (state: ToastProps) => void;

export type ErrorCode = keyof typeof ERROR_CODES;

export type ErrorParams = [] | { [key: string]: any };

export enum Permisions {
  PERMISSION_UNSPECIFIED = 0,
  PERMISSION_PLATFORM_ADMIN = 1,
  PERMISSION_PASSWORD_RECOVERY = 2,
  PERMISSION_PASSWORD_CHANGE = 3,
  PERMISSION_ADMIN_SUIT = 4,
}

export type Permission = keyof typeof Permisions;

export interface User {
  firstName: string;
  availableSystems: string[];
  purpose: UserPurposeEnum;
  lastName: string;
  username: string;
  schoolIds: string[];
  districtId: string;
  profileType: string;
  state: string;
  email: string;
  districtUuid: string;
  permissions?: Permission[];
  type: UserTypeEnum;
  userId: string;
  gradeLevel: GradeLevelEnum;
  userRoles: GetLookUpRolesQuery['lookupRoles'];
  impersonated: boolean;
}

export interface BaseUser {
  firstName: string;
  lastName: string;
  username: string;
  schoolIds: string[];
  districtId: string;
  profileType: string;
  state: string;
  email: string;
  districtUuid: string;
  permissions?: Permission[];
  type: string;
  userId: string;
}

export interface MultiSelectOption {
  label: string;
  value: string | boolean;
}

export type ArrElement<ArrType> = ArrType extends readonly (infer ElementType)[]
  ? ElementType
  : never;

export enum IconVariantType {
  Share,
  RightArrow,
  Hidden,
  SquareGrid,
}

export interface CalculateStatusObject {
  type: StudentAssignmentStatusEnum | string;
  grade?: number | string | null;
  returnedAt?: Date;
}

export enum ViewTypes {
  Tile = 'tile',
  List = 'list',
}

export interface DeviceSizes {
  base: number;
  sm: number;
  md: number;
}

export interface Mark {
  response_id: string;
  question_type: string;
  dt_score_updated: Date;
  automarkable: boolean;
  attempted: boolean;
  score: number;
  max_score: number;
  question_reference: string;
  item_reference: string;
  response: {
    value: string[];
    type: string;
    apiVersion: string;
    revision: number;
  };
}

export interface Pagination {
  pageIndex: number;
  pageSize: number;
}

export interface WorkingLocationContext {
  application?: Extract<
    ApplicationEnum,
    | ApplicationEnum.STUDENT_SUIT
    | ApplicationEnum.PARENT_SUIT
    | ApplicationEnum.TEACHER_SUIT
    | ApplicationEnum.CAMPUS_LEADER_SUIT
    | ApplicationEnum.CAMPUS_CURRICULUM_LEADER_SUIT
    | ApplicationEnum.DISTRICT_CURRICULUM_LEADER_SUIT
    | ApplicationEnum.PLATFORM_ADMIN
    | ApplicationEnum.ADMIN
    | ApplicationEnum.MATHNATION_SUIT
  >;
  currentSchoolId?: string;
  setCurrentSchoolId: (schoolId: string) => void;
  permissions: Permisions[];
  schoolIds: string[];
  userType?: string;
  currentAppSchoolIds?: string[];
}

export type FilterTypes = {
  min_time_started?: string;
  max_time_started?: string;
  users?: {
    id: string;
    name: string;
  }[];
} | null;

export type SpeakingRate = 'slow' | 'regular' | 'fast';

export interface Preferences {
  descriptiveAudio: boolean;
  fontFamily: FontFamilyEnum;
  fontSize: FontSizeEnum;
  theme: ThemeEnum;
  speakingRate: SpeakingRate;
  highlight: boolean;
  englishVoice: string;
  spanishVoice: string;
}

export enum AuthError {
  Locked = 'ACCOUNT_LOCKED',
  InvalidCode = 'INVALID_CODE',
  ExpiredCode = 'EXPIRED_CODE',
}

export interface Sidebar {
  isOpen: boolean;
}

export interface Access {
  coding?: boolean;
  setupWizard?: boolean;
}

export interface Auth {
  isLoggedIn: boolean;
  isAuthenticating: boolean;
  profileType?: ProfileTypeEnum;
  user: User;
  access?: Access;
}

export type ApplicationSettingsReducer = Record<string, any>;

export type SetAuth = (newAuth: Auth) => void;

export interface AccessibilitySettings {
  accTheme?: string;
  accTypeSize?: string;
  accSpeechSpeed?: SpeedName;
  accSpeechHighlightWords?: boolean;
  accTypeFace?: string;
}

export type InitScienceBotType = {
  text?: string | null | undefined;
  __typename?: 'ScienceBot' | undefined;
  id: string;
};

export enum FilterInputValueEnum {
  Object = 'OBJECT',
  ObjectArray = 'OBJECT_ARRAY',
}

export interface Standard {
  breadcrumb: string;
  uuid: string;
  title: string;
  HumanCodingScheme: string;
}

export type GqlResponse<Data, PropertyName extends string> = {
  [key in PropertyName]: Data;
};

export interface Answer {
  automarkable: boolean;
  maxScore: number | null;
  score: number | null;
  responseId: string;
}

export enum HideElement {
  Yes = 'Yes',
  No = 'No',
}

export enum ScopeType {
  Scope = 'SCOPE',
  LessonScope = 'LESSON',
  HowTo = 'HOW_TO',
  FactFluency = 'FACT_FLUENCY',
  DailyNumeracy = 'DAILY_NUMERACY',
}

export * from './localStorageTypes';
export * from './frostContentPlayer';

export type CustomStudentView = {
  identifier?: string;
  viewId?: string;
  elementName?: string;
  hide?: string;
  href?: string;
  referenceId?: string;
};

export type PlanElementType = {
  elementId?: string;
  identifier: string;
  elementName: string;
  teacherView?: string;
  studentView?: CustomStudentView[];
  activityTime?: string;
  scopeId?: string;
  scopeName?: string;
  sectionId?: string;
  sectionName?: string;
  projectId?: string;
  viewId?: string;
  curriculumArea?: string;
  gradeLevel?: string;
  referenceId?: string;
};

export interface ScopeMetadata {
  gradeLevel: string;
  editionName?: string;
  curriculumArea: string;
  topic: string[];
}

export interface Section {
  identifier: string;
  title: string;
  elements: Element[];
  metadata: {
    sectionTitle: string;
    hide?: HideElement;
  };
}

export interface Element {
  identifier: string;
  title: string;
  sectionId: string;
  metadata?: {
    sectionType?: 'Landing Page' | 'Side Bar' | 'Top Bar';
    elementName?: string;
    activityTitle?: string;
    curriculumArea: string;
    hide: HideElement;
    activityTime?: string;
    elementIconType?: string;
  };
  tags: string[];
  studentView: BaseView[];
  teacherView: BaseView;
  elementFiles: File[];
  coverImage?: string;
}

export interface File {
  identifier?: string;
  title: string;
  href: string;
  metadata?: Record<string, any>;
}

export interface BaseView {
  metadata?: {
    activityTitle?: string;
    priority?: string;
    curriculumArea?: string;
    elementIconType?: string;
    elementName?: string;
    hide?: 'Yes';
    page_orientation?: string;
    nameDateLine?: string;
  };
  identifier?: string;
  title: string;
  href: string;
  taxonomy?: any[];
  lorResources?:
    | Array<{
        type?: string;
        referenceId?: string;
        mode?: string;
      } | null>
    | [];
}

export interface Scope
  extends Omit<ScopeQuery, 'metadata' | 'home' | 'sections' | 'taxonomy'> {
  metadata?: ScopeMetadata;
  home?: {
    identifier: string;
    landingPage: Element;
    sideBar: Element[];
  };
  sections?: Section[];
  taxonomy?: Standard[];
}

export interface SearchableScope
  extends Omit<SearchableScopeQuery, 'metadata' | 'taxonomy'> {
  metadata?: ScopeMetadata;
  taxonomy?: Standard[];
}

export type ReactIcon = FunctionComponent<
  SVGProps<SVGSVGElement> & { title?: string | undefined }
>;

export interface TransformedElements {
  [key: string]: {
    [key: string]: TransformedElement;
  };
}

export interface TransformedElement extends Element {
  sectionId: string;
  sectionTitle?: string;
  metadata?: {
    sectionType?: 'Landing Page' | 'Side Bar' | 'Top Bar';
    elementName?: string;
    activityTitle?: string;
    curriculumArea: 'Science';
    hide: HideElement;
    activityTime?: string;
    scopeDisplayName?: string;
    sectionTitle?: string;
    elementIconType?: string;
  };
}

export interface TransformedSection extends Omit<Section, 'elements'> {
  elements: ({
    metadata: {
      sectionTitle: string;
      scopeDisplayName: string;
    } & Partial<Element['metadata']>;
  } & Omit<Element, 'metadata'>)[];
}

export type AllScopesFilterKeys =
  | 'gradeLevel'
  | 'topic'
  | 'curriculumArea'
  | 'recentlyViewed';

export type PlanType = {
  scopeId?: string;
  scopePlanId?: string | null;
  curriculumArea: string;
  grade: string;
  elements?: Array<PlanElementType>;
  startDate?: string | null;
  endDate?: string | null;
  version: number;
  scopeName?: string;
  sectionId?: string;
  sectionName?: string;
  projectId?: string;
};

export type ScopeElement = {
  id?: string;
  displayName?: string;
  instructionalDays?: string | number | null;
  startDate?: string;
  endDate?: string;
  myPlanElements?: PlanElementType[];
  elements?: PlanElementType[] | null;
  activityTime?: string;
  order?: number | null;
};

export type ViewType = 'teacherView' | 'studentView';

export interface SearchableScope
  extends Omit<SearchableScopeQuery, 'metadata' | 'taxonomy'> {
  metadata?: ScopeMetadata;
  taxonomy?: Standard[];
}

export interface GetScopesQuery {
  __typename?: 'Query';
  scopes: {
    __typename?: 'Scopes';
    collection?: SearchableScope[] | null;
    pageInfo?: PageInfo | null;
  };
}

export type ScopesListTypes = 'list' | 'tile';

export interface CalendarSettingsDrawerType {
  calendarId: string | null;
  isDefault: boolean;
  settingsType: SettingsType;
  settingDrawerOpen: boolean;
  editable?: boolean;
}

export enum SettingsType {
  INITIAL = 'INITIAL',
  NEW = 'NEW',
  EDIT = 'EDIT',
}

export type NewPacingGuideType = {
  __typename?: 'PacingGuide';
  id?: string;
  _id?: string;
  name?: string | null;
  version?: number | null;
  selectedCalendar?: any | null;
  curriculumArea?: string | null;
  grade?: string | null;
  pacingGuideType?: PacingGuideTypeEnum | null;
  scopeEditable?: boolean | null;
};

export interface PacingGuideDrawerType {
  drawerOpen: boolean;
  drawerType: SettingsType;
  pacingGuideId: string;
}

export type PacingGuideListType = Array<{
  __typename?: 'PacingGuide';
  id: string;
  _id: string;
  name?: string | null;
  pacingGuideId?: string | null;
  pacingGuideType?: PacingGuideTypeEnum | null;
  curriculumArea?: string | null;
  grade?: string | null;
  isSelected?: boolean | null;
  editable?: boolean | null;
  creator?: string | null;
  schoolId?: string | null;
  scopes?: any | null;
  version?: number | null;
  scopeEditable?: boolean | null;
} | null> | null;

export type PacingGuidesDataType = {
  [key: string]: {
    data?: ScopesPacingJoinedQuery;
    loading?: boolean;
  };
};

export type PlansListType = {
  list: PlansListArrayType;
  fetchingPlansList: boolean;
};

export type PlansListArrayType = Array<{
  __typename?: 'ScopePlan';
  _id: string;
  id: string;
  name?: string | null;
  grade?: string | null;
  curriculumArea?: string | null;
  isSelected?: boolean | null;
  editable?: boolean | null;
  version?: number | null;
  scopePlanType?: string | null;
  approachType?: ScopePlanApproachEnum | null;
} | null>;

export type TeamsDataArray = Array<{
  __typename?: 'Team';
  id: string;
  name: string;
}> | null;

export type View = 'month' | 'week' | 'work_week' | 'day' | 'agenda';

export interface UserSelectionType {
  selectedCalendar: SelectedCalendarType;
  viewMode: View;
}

export interface SelectedCalendarType {
  [key: string]: string | boolean;
}

export interface FiltersReducer {
  scopes: {
    search?: string;
    filters?: { [key: string]: string };
  };
  streaming: {
    search?: string;
    filters?: { [key: string]: string };
  };
  scopesWidget: {
    search: string;
  };
}

export interface TransformedResourceElements {
  [key: string]: TeacherResourceSectionElement[];
}

export interface TeacherResource
  extends Omit<
    TeacherResourceQuery,
    'metadata' | 'sectionTitles' | 'sections'
  > {
  metadata?: { curriculumArea: string };
  sectionTitles?: TeacherResourceSectionTitle[];
  sections?: TeacherResourceSection[];
}

export interface TeacherResourceSectionElement {
  elementFiles: string[];
  href: string;
  identifier: string;
  metadata: { elementIconType: string; elementName: string };
  taxonomy: Standard[];
  title: string;
  image: string;
}

export type TeacherResourceSectionTitle = Pick<
  TeacherResourceSection,
  'identifier' | 'title' | 'image'
>;

export interface TeacherResourceSection {
  elements: TeacherResourceSectionElement[];
  identifier: string;
  metadata: {
    contentGroup: string;
    editionName: string;
    sectionTitle: string;
  };
  title: string;
  image: string;
}

export interface PreparedDistrictAssignmentData {
  transformedStatus: StudentAssignmentStatusTransformed | null;
  firstName?: string;
  lastName?: string;
  time: {
    startedAt?: Date;
    completedAt?: Date;
    endDate?: Date;
  };
  answers: LearnosityAnswer[] | null;
  class?: string;
  studentId?: string;
  id: string;
  grade?: string | null;
  submittedAt: any;
  completedAt: any;
  shareResults?: ShareResults | null;
  assignmentId?: string;
  schoolId?: string;
}

export enum TemplateTitleValidationStatusType {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  REMAIN = 'REMAIN',
  ORIGIN = 'ORIGIN', // for edit template original name
  ERROR = 'ERROR', // when API throw error
}

export type GridSize = '1' | '2' | '3' | '4' | '5' | '6';

export enum ReceivedMessage {
  Resize = 'resizeContent',
}

export type AudienceName = 'Teacher' | 'Student' | 'Parent';

export enum DashboardTypeEnum {
  default,
  custom,
}

export enum ButtonActionEnum {
  playVideo = 'playVideo',
  downloadPdf = 'downloadPdf',
  downloadDoc = 'downloadDoc',
  navigateNewTab = 'navigateNewTab',
}

export interface ContentSectionButton {
  id?: string;
  caption: string;
  action: { value: ButtonActionEnum; label: string };
  url: string;
  active: boolean;
}

export interface ContentSection {
  id?: string;
  title: string;
  description: string;
  active: boolean;
  buttons: ContentSectionButton[];
}

export interface DashboardForm {
  dashboardType: DashboardTypeEnum;
  contentSections: ContentSection[];
  pageHeader?: string;
  welcomeVideo: {
    videoLink?: string;
    videoBackgroundImage?: string;
  };
}

export interface UiCustomization {
  dashboard: DashboardForm;
  login: LoginForm;
}

export interface LoginForm {
  dashboardType: DashboardTypeEnum;
  productDescriptionTab: ProductDescriptionTab;
  welcomeVideoTab: WelcomeVideoTab;
  resourcesTab: ResourcesTab;
  homeTab: HomeTab;
  seoPreviewImage: string;
  seoDescription: string;
  seoTitle: string;
  registrationUrl: string;
  logoAltText: string;
  pageHeader: string;
  logoLink: string;
  showRegistrationLink: boolean;
}

export interface ResourcesTab {
  active: boolean;
  shortTabCaption: string;
  fullTabCaption: string;
  resourcesInnerTabs: ResourcesInnerTab[];
}

export interface ResourcesInnerTab {
  active: boolean;
  fullInnerTabCaption: string;
  shortInnerTabCaption: string;
  resourceSectionHeader: string;
  resourceDescription: string;
  resourcePreviewImage: string;
  previewImageAltText: string;
  colorMode: InnerTabColorModeEnum;
  buttons: ContentSectionButton[];
}

export enum InnerTabColorModeEnum {
  ORANGE = 'orange',
  RED = 'red',
  ROSE = 'rose',
  GREEN_LIGHT = 'greenLight',
  GREEN_MEDIUM = 'greenMedium',
  GREEN_DARK = 'greenDark',
  FUCHSIA_LIGHT = 'fuchsiaLight',
  FUCHSIA_MEDIUM = 'fuchsiaMedium',
  BLUE = 'blue',
}

export interface ProductDescriptionTab {
  colorMode: 'regular' | 'invert';
  productDescription: string;
  header: string;
  shortTabCaption: string;
  fullTabCaption: string;
  active: boolean;
  backgroundImage: string;
}

export interface WelcomeVideoTab {
  welcomeRegionBackground: string;
  backgroundImage: string;
  videoCaption: string;
  videoPoster: string;
  videoUrl: string;
  shortTabCaption: string;
  fullTabCaption: string;
  active: boolean;
}

export interface HomeTab {
  welcomeRegionBackground: string;
  backgroundImage: string;
  stateIconUrl: string;
  showWelcomeText: boolean;
  welcomeText: string;
  audienceAddressing: string;
  shortTabCaption: string;
  fullTabCaption: string;
  active: boolean;
}

export enum ModuleEnum {
  classes = 'class',
  learningMaterials = 'learningMaterials',
  students = 'student',
  assessment = 'assessment',
  service = 'service',
  wizard = 'wizard',
  coding = 'coding',
  learningBot = 'learningBot',
  lessonPlanner = 'lessonPlanner',
}

export enum OperationEnum {
  create = 'create',
  assign = 'assign',
  print = 'print',
  feedback = 'feedback',
  view = 'view',
  update = 'update',
  remove = 'remove',
  addStudents = 'addStudents',
  addTeachers = 'addTeachers',
  editPassword = 'editPassword',
  impersonate = 'impersonate',
  access = 'access',
}

export enum BehaviourExtraEnum {
  Skip = 'SKIP',
}
